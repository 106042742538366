/* global fetch, AZURE_URI, LOCALE */
import { AppError, PhobsError, RestError, ErrorLevel } from './classes/Errors'
import { Unit }  from './classes/Unit'
import { Rate }  from './classes/Rate'
import PriceQuote from './classes/PriceQuote'

const getPropertyAsync = async (propertyId, locale) => {
  if (!propertyId) {
    throw new AppError('propertyIdMissing', ErrorLevel.FATAL)
  }
  if (!locale || !locale.length === 2) {
    throw new AppError('wrongLocale', ErrorLevel.FATAL)
  }
  const response = await fetch(`${AZURE_URI}GetProperty?id=${propertyId}&locale=${locale}`, {
    method: 'GET'
  })
  let property = null
  try {
    property = await response.json()
  } catch (e) {
    throw new AppError('getProperty', ErrorLevel.FATAL)
  }

  if (property.Error) {
    throw new AppError('getProperty', ErrorLevel.FATAL)
  }
  if (property.PhobsError) {
    throw new PhobsError(property.PhobsError.id, property.PhobsError, ErrorLevel.FATAL)
  }
  const result = {
    phobsId: property.phobsId,
    image: property.image,
    brandLogo: property.brandLogo,
    brand: property.brand,
    title: property.title,
    link: property.link,
    destination: property.destination,
    maxChildAge: property.maxChildAge,
    maxGuests: property.maxGuests,
    maxAdults: property.maxAdults,
    maxChildren: property.maxChildren,
    menuGroup: property.menuGroup,
    stars: parseInt(property.stars),
    units: []
  }
  property.units.forEach(rawUnit => {
    const unit = new Unit(rawUnit)
    result.units.push(unit)
  })
  return result
}

const getPropertyAvailabilityAsync = async cart => {
  if (!cart || !cart.propertyId || cart.propertyId === '') {
    throw new Error("noPropertyId")
  }
  if (!cart.date) {
    throw new Error("noDate")
  }
  const getPricePairByType = (ratePlan, priceType) => {
    const regular = new Rate(ratePlan, priceType)
    const member =  ratePlan.member ? new Rate(ratePlan.member, priceType) : null
    return { regular, member }
  }
  const rawObj = await _PostApi(cart, 'PostPropertyAvailabilityPlus', "&includeRestricted=true")
  const result = []
  Object.keys(rawObj).forEach((unitId) => {
     const unit = new Unit(rawObj[unitId])
     Object.keys(rawObj[unitId].RatePlans).forEach(rateId => {
      const ratePlan = rawObj[unitId].RatePlans[rateId]
      const pricePair = getPricePairByType(ratePlan, 'Price')
      if (ratePlan.Rate.PricePerson?.['#text']) {
        pricePair.perPerson = getPricePairByType(ratePlan, 'PricePerson')
      }
      unit.rates.push(pricePair)
      unit.extras = ratePlan.Extras
      unit.disclaimer = ratePlan.Disclaimer
     })
     unit.rates = unit.rates.sort((a, b) => {
      if (a.regular.weight && b.regular.weight) {
        return a.regular.weight - b.regular.weight;
      } else if (a.regular.weight) {
        return -1;
      } else if (b.regular.weight) {
        return 1;
      } else {
        return a.regular.price - b.regular.price;
      }
     })
     result.push(unit)
  })
  return result
}

const getPriceQuoteAsync = async (cart) => {
  if (!cart.units || !cart.units.length) {
    throw new Error("cartEmpty")
  }
  const priceQuote = await _PostApi(cart, 'PostPriceQuote')
  return new PriceQuote(priceQuote)
}

const postBookingAsync = cart => _PostApi(cart, 'PostNewBooking')

const getDescRatePlan = async (rateId) => {
  if (!rateId) {
    throw new Error("rateId empty")
  }
  const response = await fetch(`${AZURE_URI}GetDescRatePlan?locale=${LOCALE}&ratePlanId=${rateId}`, {
    method: 'GET',
  })
  const json = await response.json()
  return json
}

export {
  getPropertyAsync,
  getPropertyAvailabilityAsync,
  getPriceQuoteAsync,
  postBookingAsync,
  getDescRatePlan,
}

// private

const _PostApi = async (cart, api, extraParameters = "") => {
  const response = await fetch(`${AZURE_URI}${api}?locale=${LOCALE}${extraParameters}`, {
    method: 'POST',
    body: JSON.stringify(cart)
  })

  const json = await response.json()

  // return empty array without error if error is about availability
  if (json.PhobsError && ['0', '4004'].includes(json.PhobsError.id)) return []

  if (json.PhobsError) {
    throw new PhobsError('json.PhobsError', json.PhobsError, json.Error)
  }

  if (json.Error) {
    throw new RestError('json.Error', json.Error)
  }

  if (api === 'PostNewBooking' && !json?.Reservation?.ReservationID['@Id']) {
    throw new PhobsError('PostNewBooking', { code: -2, PhobsRequest: '', PhobsResponse: JSON.stringify(json)  })
  }

  return json
}
