import { removeUnitByIndex, useCart } from '../../use-cart'
import { formatDateForMaistra } from '../../utils-common'

export default {
  name: 'ReservationSummary',
  template: `
<div class="reservation-summary" :class="{'reservation-summary--open': isOpen, 'reservation-summary--disclaimer': buttonDisclaimer != ''}">
  <div class="reservation-summary__title">
    <h4>{{ $t('reservationSummary.title') }}</h4>
    <span @click="isOpen ? $emit('close') : $emit('open')" role="button">
      {{ isOpen ? $t('bookingModal.closeBtn') : $t('bookingModal.openBtn') }}
    </span>
  </div>

  <div class="reservation-summary__content">
    <h4 class="hide-on-print">{{ property.title }}</h4>
    <p :class="'theme--' + property.brand" class="stars hide-on-print">
      <i v-for="n in property.stars" class="icon-star"></i>
    </p>
    <hr class="hide-on-print">

    <p v-if="guestSummarySource === 'booking-bar'">
      {{ cart.adults.value + cart.children.value.length }} {{ $t('reservationSummary.guests') }} / {{ cart.adults.value }}
      {{ $t('reservationSummary.adults') }}<template v-if="property.maxChildAge !== 0">, {{ cart.children.value.length }} {{ $t('reservationSummary.children') }}</template>
    </p>
    <p v-else>
      {{ cart.guestSummary.value.adults + cart.guestSummary.value.children }} {{ $t('reservationSummary.guests') }} / {{ cart.guestSummary.value.adults }}
      {{ $t('reservationSummary.adults') }}<template v-if="property.maxChildAge !== 0">, {{ cart.guestSummary.value.children }} {{ $t('reservationSummary.children') }}
      {{ childrenSummary }}</template>
    </p>
    <hr class="hide-on-print">

    <p>{{ formatDateForMaistra(cart.startDate.value) }} - {{ formatDateForMaistra(cart.endDate.value) }} / {{ cart.nights }} {{ $t('reservationSummary.nights') }} </p>
    <hr class="hide-on-print">

    <div v-if="cart.rateId && hasPriceQuote">
      <h4>{{ cart.ratePlan.value.name }}</h4>
      <hr class="hide-on-print">

      <div v-for="(unit, key) in priceQuote.units" class="reservation-summary__unit">
        <p class="reservation-summary__unit__number">
          <span>{{ $t('reservationSummary.unit') }} {{ key + 1 }}:</span>
          <a role="button" :title="$t('reservationSummary.removeUnit')" @click="() => removeUnitByIndex(key)" class="reservation-summary__unit__remove" v-if="canRemoveUnit"><i class="icon-trash"></i></a>
        </p>

        <p v-for="item in unit.priceItems" class="reservation-summary__price" :class="{ 'reservation-summary__price--base': item.type === 'BASE', discount: item.type === 'REDUCTION'  }">
          {{ item.name }}
          <span>{{ item.type === 'REDUCTION' ? '-' : '' }}{{ item.price.display }}</span>
        </p>

        <p class="reservation-summary__price">
          {{ $t('reservationSummary.unitTotal') }}
          <span>{{ unit.total.display }}</span>
        </p>
      </div>

      <div v-if="priceQuote.grandTotal && priceQuote.alternateTotal && cart.ratePlanIsMemberOnly.value" class="grid no-gap reservation-summary__alternate-total">
        <div class="col-6">
          <p>{{ $t('reservationSummary.total') }} {{ $t('reservationSummary.regularRate') }}</p>
        </div>
        <div class="col-6" style="text-align: right">
          <p class="reservation-summary__price">{{ priceQuote.alternateTotal.display }}</p>
        </div>
        <div class="col-6">
          <p class="reservation-summary__price">{{ $t('reservationSummary.youSave') }}</p>
        </div>
        <div class="col-6" style="text-align: right">
          <p class="reservation-summary__price">{{ priceQuote.youSave?.display }}</p>
        </div>
      </div>

      <div>
        <p class="reservation-summary__price reservation-summary__price--total">
          <span>{{ $t('reservationSummary.total') }}</span>
          {{ cart.ratePlanIsMemberOnly.value ? $t('reservationSummary.memberRate') : $t('reservationSummary.regularRate')  }}
          <span>{{ priceQuote.grandTotal?.display }}</span>
        </p>
      </div>
    </div>

    <div v-else>
      <p>{{ $t('reservationSummary.selectRatePlan') }}</p>
      <hr>
    </div>
  </div>


  <div id="continue-button"></div>
</div>`,
  props: { priceQuote: Object, property: Object, isOpen: Boolean, canRemoveUnit: Boolean, guestSummarySource: String },
  emits: [ 'open', 'close' ],
  setup(props) {
    const { cart } = useCart()
    const { isOpen } = Vue.toRefs(props)
    const hasPriceQuote = Vue.computed(() => props.priceQuote?.rateId)
    const childrenSummary = Vue.computed(() => cart.childAges.value?.length > 0 ? `(${cart.childAges.value.join(', ')})` : '')

    Vue.watch(isOpen, (show) => {
      if (show && window.matchMedia('(max-width: 768px)').matches) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    })

    return {
      cart,
      isOpen,
      hasPriceQuote,
      formatDateForMaistra,
      childrenSummary,
      removeUnitByIndex,
    }
  }
}
