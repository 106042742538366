/* global VueI18n, LOCALE */

import { datetimeFormats, messagesCommon } from './translations-common'
import { mergeDeep } from '../utils-common'

const CreateI18n = (messagesSpecific) => {
  return VueI18n.createI18n({
    legacy: false,
    locale: LOCALE,
    datetimeFormats,
    messages: mergeDeep(messagesCommon, messagesSpecific),
    warnHtmlMessage: false,
  })
}

export { CreateI18n }
