/* global WWW_HOME_URL, PREFIX */

import { isEmpty } from '../../utils'
import { useCart, map2SerializedUnits } from '../../use-cart'
import { setErrorAndStopLoading } from './Notifications'
import Modal from './Modal'
import RatePolicyModal from './RatePolicyModal'
import * as Api from '../../api'
import { Price } from '../../classes/Price'

export default {
  name: 'RatePlan',
  components: { Modal, RatePolicyModal },
  template: `
<div class="rateplan">
  <h4 class="no-margin uppercase">
    {{ ratePair.regular.name }}
    <span class="debug">[{{ ratePair?.regular?.id }}/{{ ratePair?.member?.id || 'noLoyaltyRate' }}]</span><br>
  </h4>

  <div class="rateplan__content">
    <div>
      <div>
        <h5>{{ $t(ratePair.regular.priceType) }}</h5>
        <p>
          {{ $t('ratePlan.regular') }}
          <span class="dashes"></span>
          <span v-if="ratePair.member" class="font-maistra-display"><s>{{ pricePerNight.regular.display }}</s></span>
          <span v-else class="font-maistra-display">{{ pricePerNight.regular.display }}</span>
        </p>
        <p v-if="ratePair.member">
          {{ $t('ratePlan.member') }}
          <span class="dashes"></span>
          <span class="font-maistra-display rateplan__has-info">
            <i class="icon-info" role="button" @click="memberInfoOpened = true"></i>
            {{ pricePerNight.member.display }}
          </span>
        </p>
      </div>

      <div v-if="ratePair.perPerson && ratePair.perPerson.regular.price > 0">
        <p class="desktop-column">
          <span class="h5">{{ $t('PerPersonNight') }}</span>
          <span class="dashes"></span>
          <span class="font-maistra-display">{{ ratePair.perPerson.regular.display }}</span>
        </p>
      </div>

      <div v-show="!unitLimitReached">
        <h5 v-if="!isEmpty(priceQuote)">{{ $t('ratePlan.totalFor') }} {{ nights }}{{ $t('ratePlan.nights') }} / {{ noOfUnits }} {{ $t('ratePlan.rooms') }}</h5>
        <p v-if="priceQuote.alternateTotal">
          {{ $t('ratePlan.regular') }}
          <span class="dashes"></span>
          <span class="font-maistra-display"><s>{{ priceQuote.alternateTotal?.display }}</s></span>
        </p>
        <div class="rateplan__has-policy">
          <p>
            {{ priceQuote.alternateTotal ? $t('ratePlan.member') : $t('ratePlan.regular') }}
            <span class="dashes"></span>
            <span class="rateplan__exposed">{{ priceQuote.grandTotal?.display }}</span>
          </p>
          <p><span class="rateplan__policy" @click="ratePolicyOpened = true">{{ $t('ratePlan.ratePolicy') }}</span></p>
        </div>
      </div>
    </div>
    <hr>
    <div v-if="!unitLimitReached">
      <p v-show="currentRatePlan.restrictions?.StayMin" class="desktop-column">
        <span class="h5">{{ $t('ratePlan.minStay') }}</span>
        <span class="dashes"></span>
        <span v-if="increaseNumberOfNights === 0" class="font-maistra-display">{{ currentRatePlan.restrictions?.StayMin + $t('ratePlan.nights') }}</span>
        <span v-else class="font-maistra-display color-blue">{{ increaseNumberOfNights + $t('ratePlan.nights') }} <i class="icon-warning"></i></span>
      </p>

      <p v-show="currentRatePlan.rate?.Board" class="desktop-column">
        <span class="h5">{{ $t('stepTwo.serviceType') }}</span>
        <span class="dashes"></span>
        <span class="font-maistra-display">{{ $t('serviceType.' + currentRatePlan.rate?.Board) }}</span>
      </p>

      <p v-if="!isEmpty(priceQuote)">
        <button
          class="button button--primary"
          @click='$emit("addToCart", { ratePair, unitId: unit.id, increaseNumberOfNights })'>
            {{ $t('ratePlan.add') }} <template v-if="increaseNumberOfNights > 0"> / {{ increaseNumberOfNights + $t('ratePlan.nights') }}</template>
        </button>
      </p>
    </div>

    <div v-show="unitLimitReached" class="rateplan__content__error">
      <p>{{ $t('stepOne.unitLimitReached') }}<br>
      <a class="button button--outline" :href="WWW_HOME_URL + PREFIX + '/contact'">{{ $t('unitDemand.contactButton') }}</a></p>
    </div>
  </div>

  <details v-if="extraInformation.length">
    <summary>
      <div>{{ $t('ratePlan.info') }}</div>
    </summary>
    <div>
      <ul>
        <li v-for="info in extraInformation">{{ info }}</li>
      </ul>
    </div>
  </details>
  <Modal
    :isOpen="memberInfoOpened"
    @closeModal="memberInfoOpened = false"
    class="rateplan__club"
    title="">
    <img src="https://res.cloudinary.com/maistra/image/upload/v1651756587/Loyalty/maistra-rewards-club-black.svg" alt="MaiStar Rewards Club" width="200" height="182">
    <div v-html="$t('ratePlan.memberPriceInfo')">
  </Modal>

  <RatePolicyModal :isOpened="ratePolicyOpened" :rateId="ratePair.regular?.id" :extraInformation="extraInformationRatePolicy" @success="ratePolicyOpened = false" />
</div>
  `,
  props: {
    extras: String,
    ratePair: Object,
    unit: Object,
    maxChildAge: Number,
    isMember: Boolean,
  },
  emits: ['addToCart', 'onlyOnDemand'],
  setup (props, { emit }) {
    const { cart } = useCart()
    const noOfUnits = Vue.ref(0)
    const increaseNumberOfNights = Vue.ref(0)
    const priceQuote = Vue.reactive({})
    const notAvailible = Vue.ref(false)
    const unitLimitReached = cart.isFull
    const { ratePair, unit, isMember, extras } = Vue.toRefs(props)
    const pricePerNight = Vue.reactive({
      regular: ratePair.value?.regular,
      member: ratePair.value?.member,
    })
    const currency = ratePair.value.regular.currency
    const memberInfoOpened = Vue.ref(false)
    const ratePolicyOpened = Vue.ref(false)
    const extraInformation = Vue.ref([])
    const extraInformationRatePolicy = Vue.ref([])

    const nights = Vue.computed(() => {
      return increaseNumberOfNights.value > 0 ? increaseNumberOfNights.value : cart.nights.value
    })

    const fetchMoreInformation = async () => {
      try {
        const json = await Api.getDescRatePlan(ratePair.value.regular.id)
        const rateExtras = json.RatePlanDesc.RateExtras
        if (rateExtras && Array.isArray(rateExtras)) {
          extraInformation.value = rateExtras
          extraInformationRatePolicy.value = rateExtras
        }
        if (json.RatePlanDesc.RatePlan.ShortDescription) {
          extraInformation.value.push(json.RatePlanDesc.RatePlan.ShortDescription)
        }
        if (extras.value?.length) {
          extraInformation.value.push(extras.value)
          extraInformationRatePolicy.value.push(extras.value)
        }
      } catch (err) {
        setErrorAndStopLoading(err)
      }
    }

    function setPricePerNight () {
      pricePerNight.regular = new Price(priceQuote?.grandTotal?.price / nights.value, 'EUR')
      if (priceQuote.alternateTotal) {
        pricePerNight.regular = new Price(priceQuote?.alternateTotal?.price / nights.value, 'EUR')
        pricePerNight.member = new Price(priceQuote?.grandTotal?.price / nights.value, 'EUR')
      }
    }

    const fetchPriceQuote = async () => {
      try {
        const baseCartForApi = cart.getSerializedForQuote()
        if (cart.nights.value < currentRatePlan.value.minStay) {
          increaseNumberOfNights.value = currentRatePlan.value.minStay
          baseCartForApi.nights = currentRatePlan.value.minStay
        }

        const unitPlacement = [
          {
            adults: unit.occupancyMin,
            children: [],
            services: [],
            ...unit.value,
          }
        ]

        baseCartForApi.units = map2SerializedUnits(unitPlacement)
        noOfUnits.value = baseCartForApi.units.length
        baseCartForApi.rateAltId = ratePair.value.member != null ? ratePair.value.regular.id : null
        baseCartForApi.rateId = ratePair.value.member ? ratePair.value.member.id : ratePair.value.regular.id
        if (unitPlacement?.length) {
          const quote = await Api.getPriceQuoteAsync(baseCartForApi)
          if (quote.rateId) {
            Object.assign(priceQuote, quote)
            if (unit.value.subProperty !== 'pitches') setPricePerNight()
          } else {
            console.warn('no availiblity?')
            notAvailible.value = true
          }
        }
      } catch (err) {
        console.dir(err);
        if (err?.code === "notEnoughAdults")  {
          emit('onlyOnDemand', 'notEnoughAdults')
        } else if (err.code === "phobsError5008") {
          emit('onlyOnDemand', 'tooManyGuests')
        } else {
          setErrorAndStopLoading(err)
        }
      }
    }

    Vue.onMounted(async () => {
      await Promise.allSettled([fetchPriceQuote(), fetchMoreInformation()])
    })

    const currentRatePlan = Vue.computed(() => {
      return isMember.value && ratePair.value.member ? ratePair.value.member : ratePair.value.regular
    })

    return {
      isEmpty,
      noOfUnits,
      priceQuote,
      currentRatePlan,
      notAvailible,
      unitLimitReached,
      cart,
      memberInfoOpened,
      ratePolicyOpened,
      extraInformation,
      extraInformationRatePolicy,
      increaseNumberOfNights,
      nights,
      pricePerNight,
      WWW_HOME_URL,
      PREFIX
    }
  }
}
