/* global Vue, VueRouter, VueI18n, primevue, calendar, LOCALE, PREFIX, DATA_LAYER  */

import Step1 from './views/Step1'
import Step2 from './views/Step2'
import Step3 from './views/Step3'
import Step4 from './views/Step4'
import Step5 from './views/Step5'
import ReservationSummary from './components/ReservationSummary'
import { useState, setErrorAndStopLoading, startLoading, stopLoading, runAsync } from './components/Notifications'
import BookingError from './components/BookingError'
import '../validation-rules'
import * as Api from '../api'
import AuthService from '../AuthService'
import { PhobsError } from '../classes/Errors'
import { isEmpty } from '../utils'
import { useCart, loadCartFromUrl, loadCartFromJson, selectRatePlanPair, updateBookingParameters, updateBookingNights, setIdentity, updateGuestLimits, clearRateId, setBecomeMember } from '../use-cart'
import BookingMenu from './components/BookingMenu'
import { messagesSpecific } from '../i18n/translations'
import { CreateI18n } from '../i18n/create-i18n'
import { localizeCalendar } from '../utils-common'
import { setCurrency } from '../use-currency-observer'

const i18n = CreateI18n(messagesSpecific)

const routes = [
  { path: '/', redirect: '/step1' },
  { path: '/step1', name: 'step1', component: Step1, meta: { title: 'chooseAccomodation' } },
  { path: '/step2', name: 'step2', component: Step2, meta: { title: 'reservationDetails' } },
  { path: '/step3', name: 'step3', component: Step3, meta: { title: 'personalDetails' } },
  { path: '/step4', name: 'step4', component: Step4, meta: { title: 'finalConfirmation' } },
  { path: '/step5/:reservationId', name: 'step5', component: Step5, meta: { title: 'thankYou' } },
  { path: '/:pathMatch(.*)', component: { template: '<h1 style="color: red">Page Not Found</h1>' } }
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(PREFIX),
  routes
})

const BookingApp = Vue.createApp({
  name: 'BookingApp',
  components: { ReservationSummary, BookingMenu, BookingError },
  template: `
<BookingError />
<div class="container">
  <div v-show="stepNumber < 5" class="step-tracker">
    <div class="step-tracker__item">
      <div :class="[stepNumber >= 1 ? 'active' : '']">1</div>
      <span>{{ $t('chooseAccomodation') }}</span>
    </div>
    <div class="step-tracker__item">
      <div :class="[stepNumber >= 2 ? 'active' : '']">2</div>
      <span>{{ $t('reservationDetails') }}</span>
    </div>
    <div class="step-tracker__item">
      <div :class="[stepNumber >= 3 ? 'active' : '']">3</div>
      <span>{{ $t('personalDetails') }}</span>
    </div>
    <div class="step-tracker__item">
      <div :class="[stepNumber >= 4 ? 'active' : '']">4</div>
      <span>{{ $t('finalConfirmation') }}</span>
    </div>
  </div>

  <div class="main-title">
    <button
      v-show="stepNumber > 1 && stepNumber < 5"
      class="main-title__back"
      @click="goToPreviusStep">
      <i class="icon-arrow-left"></i>
      <span>{{ $t('back') }}</span>
    </button>
    <h1 class="no-margin">{{ $t($route.meta.title ?? 'loading') }} </h1>
  </div>
</div>

<div v-if="stepNumber === 1 && !isEmpty(property)" class="property-details">
  <div class="container">
    <div>
      <img :src="property.brandLogo" :alt="property.brand">
      <h4 class="h3">{{ property.title }}</h4>
      <p class="no-margin property-details__destination">
        <span class="stars" :class="'theme--' + property.brand">
          <i v-for="n in property.stars" class="icon-star"></i>
        </span>
        {{ property.destination }}
      </p>
    </div>
    <hr>
    <a class="arrow-button" :href="property.link">
      <span>{{ $t('stepOne.discoverMore') }}</span>
      <i class="icon-arrow-right"></i>
    </a>
  </div>
</div>

<BookingMenu
  ref="bookingMenu"
  v-if="authEnded && stepNumber === 1"
  :propertyIds="[cart.propertyId.value]"
  :property="property"
  :startDate="cart.startDate.value"
  :nights="cart.nights.value"
  :adults="cart.adults.value"
  :children="cart.children.value"
  :accessCode="cart.accessCode.value"
  @updateBookingParams="updateBookingParams" />

<div class="container">

  <div class="grid no-row-gap">
    <div class="step col-12 col-md-8">
      <router-view
        v-if="shouldRenderStep"
        :property="property"
        :extras="extras"
        :priceQuote="priceQuote"
        @modifySearch="$refs.bookingMenu.openModal('calendar', $event)"
        @modifyGuests="$refs.bookingMenu.openModal('guests', $event)"
        @updatePrice="updatePriceAsync"
        @addToCart="addToCart"
        @closeSummary="isSummaryOpen = false" />
    </div>

    <div class="col-12 col-md-4">
      <ReservationSummary
        v-show="!isEmpty(property)"
        :class="'reservation-summary--step' + stepNumber"
        :property="property"
        :priceQuote="priceQuote"
        :isOpen="isSummaryOpen"
        :canRemoveUnit="stepNumber <= 2"
        :guestSummarySource="stepNumber === 1 ? 'booking-bar' : 'cart'"
        @open="isSummaryOpen = true"
        @close="isSummaryOpen = false">
      </ReservationSummary>
    </div>

    <div v-if="stepNumber === 5" id="step5-buttons" class="step-5__buttons col-12 col-xl-8 negative-cp-sm-down hide-on-print"></div>
  </div>
</div>
  `,
  setup () {
    const { cart } = useCart()
    const { state } = useState()
    const authEnded = Vue.ref(false)

    const filterToRateId = Vue.ref(null)
    const extras = Vue.ref('')

    const property = Vue.reactive({})
    const propertyAvailability = Vue.reactive([])
    const priceQuote = Vue.reactive({})

    const stepNumber = Vue.computed(() => parseInt(router.currentRoute.value.name?.slice(-1)))

    const shouldRenderStep = Vue.computed(() => {
      const isSoftLoadingStep1 = stepNumber.value === 1 && state.processes.value.length === 1 && state.processes.value[0] === 'loadPriceQuoteAsync'
      return cart.hasPropertyId.value && (!state.isLoading.value || isSoftLoadingStep1)
    })

    const isSummaryOpen = Vue.ref(false)

    const goToPreviusStep = () =>  {
      if (!stepNumber?.value || stepNumber.value < 2 || stepNumber.value > 4) {
        return
      }
      router.push(`/step${stepNumber.value - 1}`)
    }

    const updateBookingParams = async bookingParams => {
      updateBookingParameters(bookingParams)
      await runAsync(loadPropertyAvailabilityAsync)
    }

    Vue.watch(() => cart.dirtySignal.value, async () => await runAsync(loadPriceQuoteAsync))

    Vue.watch(stepNumber, step => {
      if (step=== 1) {
        document.body.classList.add('step-1')
      } else {
        document.body.classList.remove('step-1')
      }
      isSummaryOpen.value = false
    }, { immediate: true })

    const loadPropertyAvailabilityAsync = async () => {
      propertyAvailability.splice(0)

      const availabilityParams = {
        ...cart.getSerializedForAvailability(),
        ...(filterToRateId.value ? { rateId: filterToRateId.value } : null)
      }

      propertyAvailability.push(...await Api.getPropertyAvailabilityAsync(availabilityParams))
      // reset unit availability because response might be empty array
      property.units.forEach(u => {
        u.availableUnits = []
        u.rates = []
      })
      propertyAvailability.forEach(pa => {
        extras.value = pa.extras
        const search = property.units.filter(u => u.id === pa.id)
        if (search.length > 0) {
          search[0].availableUnits = pa.availableUnits
          search[0].rates = pa.rates
          search[0].disclaimer = pa.disclaimer
        }
      })
    }

    const loadPriceQuoteAsync = async () => {
      if (cart.hasRateId.value) {
        try {
          const pq = await Api.getPriceQuoteAsync(cart.getSerializedForQuote())
          Object.assign(priceQuote, pq)
        } catch (e) {
          if (e instanceof PhobsError && e.rawCode === 5) {
            console.warn(e.details)
          } else throw e
        }
      } else {
        Object.assign(priceQuote, { rateId: null })
      }
    }

    const addToCart = async ({ unitId, ratePair, increaseNumberOfNights}) => {
      try {
        if (!unitId) {
          throw new Error("missing unitId")
        }
        if (!ratePair || !ratePair.regular) {
          throw new Error("missing rate or regular rate")
        }
        if (increaseNumberOfNights > 0) {
          updateBookingNights(increaseNumberOfNights)
          await runAsync(loadPropertyAvailabilityAsync)
        }

        const unit = property.units.find(pa => pa.id ===  unitId)
        if (!unit) {
          throw new Error("couldn't find unit")
        }

        selectRatePlanPair(ratePair, unit)
        await loadPriceQuoteAsync()

        router.push(`/step1`)

      } catch (err) {
        setErrorAndStopLoading(err)
      }

      DATA_LAYER.bookingRateSelected({ cart, priceQuote, property })
    }

    const updatePriceAsync = () => runAsync(loadPriceQuoteAsync)

    const loaderSvg = document.getElementById('loaderSvg')
    if (loaderSvg) {
      Vue.watch(state.isLoading, isLoading => {
        if (isLoading) {
          loaderSvg.classList.remove('is-hidden')
        } else {
          loaderSvg.classList.add('is-hidden')
        }
      })
    }

    startLoading('booking-app-mount')
    // wait for AuthService to be initialized to avoid redirects breaking the flow
    AuthService.on('ended', async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('currency')) {
          setCurrency(urlParams.get('currency'))
        }
        if ('cart' in window.sessionStorage) {
          loadCartFromJson(sessionStorage.getItem('cart'))
        }
        if (!isEmpty(Object.fromEntries(urlParams.entries())) && urlParams.get("propertyId")) {
          loadCartFromUrl(urlParams)
          const ratePlanId = urlParams.get("ratePlanId")
          if (ratePlanId?.length > 1 ) {
            filterToRateId.value = ratePlanId
          }
        }
        Object.assign(property, await Api.getPropertyAsync(cart.propertyId.value, LOCALE))

        updateGuestLimits({
          maxAdults: property.maxAdults,
          maxChildren: property.maxChildren,
          maxGuests: property.maxGuests,
          maxChildAge: property.maxChildAge,
        })

        await loadPropertyAvailabilityAsync()
        await loadPriceQuoteAsync()

        if (AuthService.isAuthenticated) {
          setIdentity(AuthService.loyaltyId)
        }
        authEnded.value = true
        stopLoading('booking-app-mount')
      } catch (err) {
        setErrorAndStopLoading(err)
      }
      DATA_LAYER.bookingPageLoad({ stepNumber: stepNumber.value, property, cart })
    })

    AuthService.on('signedIn', () => {
      setBecomeMember(true)
      if (stepNumber.value > 2) {
        router.push('/step2')
      }
    })

    AuthService.on('signingOut', () => {
      clearRateId()
    })

    return {
      cart,
      state,
      authEnded,
      property,
      extras,
      priceQuote,
      stepNumber,
      isSummaryOpen,
      shouldRenderStep,
      goToPreviusStep,
      updateBookingParams,
      updatePriceAsync,
      isEmpty,
      addToCart,
    }
  }
})

BookingApp.config.globalProperties.$AuthService = AuthService // same service is importent on all apps
BookingApp.use(primevue, {
  locale: localizeCalendar(LOCALE),
})
BookingApp.use(router)
BookingApp.use(i18n)
BookingApp.mount('#app')

export default BookingApp
