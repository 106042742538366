import UnitImage from './UnitImage'

export default {
  name: 'UnitOnDemand',
  components: { UnitImage },
  template: `
<div class="unit-card">
  <div class="unit-card__container">
    <UnitImage v-if="unit.image" :unit="unit" />
    <img v-else src="https://user-images.githubusercontent.com/5190707/149798429-0a048f44-46cc-49f0-ada6-6d35a1ac4511.png" alt="No unit image" />

    <div class="unit-card__content">
      <h3>{{ unit.name }} <span class="debug">[{{ unit.id }}]</span></h3>
      <p v-if="unit.description" class="unit-card__description">{{ unit.description }}</p>

      <div class="unit-card__icons">
        <div v-if="unit.area">
          <i class="icon-area"></i>&nbsp; {{ unit.area }} m<sup>2</sup>
        </div>
        <div>
          <i v-for="i in unit.occupancyDisplayAdult" class="icon-adult"></i>
          <i v-for="i in unit.occupancyDisplayChildren" class="icon-child"></i>
        </div>
        <div v-if="unit.subProperty === 'pitches'" class="unit-card__info">
          <i class="icon-info"></i>
          <div>
            <span class="uppercase">{{ $t('unitAvailable.newPricingPolicy') }}</span><br>
            <strong>{{ $t('unitAvailable.pricePerPitch') }}</strong>,
            {{ $t('unitAvailable.upTo6People') }}
          </div>
        </div>
      </div>
      <hr>

      <p class="unit-card__note">{{ $t('unitDemand.unavailable') }}</p>
    </div>
  </div>

  <div class="unit-card__footer">
    <p class="unit-card__note">{{ $t('unitDemand.contactText') }}</p>

    <div>
      <a class="button button--outline" :href="contactURL + '&subject=' + unit.name">{{ $t('unitDemand.contactButton') }}</a>
      <button class="button button--outline" @click="$emit('modifySearch', { unitId: unit.id })">{{ $t('unitDemand.modifySearch') }}</button>
    </div>
  </div>
</div>
  `,
  props: ['unit', 'contactURL'],
  emits: ['modifySearch'],
  data() {
    return {
      WWW_HOME_URL,
      PREFIX,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0)
    },
  },
}
