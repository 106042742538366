/* global Vue, calendar */

import { useState } from './Notifications'
import { MAX_NIGHTS } from '../../enums/limits'
import { Price } from '../../classes/Price'
import { compareDates, denormalizeTimezone, normalizeTimezone } from '../../utils-common'

export default {
  name: 'BookingCalendar',
  components: { Calendar: calendar },
  template: `
    <div class="booking-calendar">
      <Calendar
        v-if="!state.isLoading.value"
        v-model="dateRange"
        selectionMode="range"
        :minDate="denormalizeTimezone(new Date())"
        :maxDate="maxDate"
        :numberOfMonths="2"
        :disabledDates="disabledDates"
        :inline="true"
        :selectOtherMonths="true"
        :showButtonBar="false"
        @date-select="onDateSelect">
        <template #date="slotProps">
          <span :class="{ checkoutOnly: isCheckoutOnly(slotProps.date) }">{{slotProps.date.day}}</span>
          <span class="p-price" v-html="getPriceForDay(slotProps.date)?.display.replace('\xa0', ' ')"></span>
        </template>
      </Calendar>

      <Teleport to="#modal-footer-teleport">
        <div class="modal__note">{{ $t('bookingModal.note') }}</div>
        <div class="modal__buttons">
          <button
            class="button no-margin-top"
            @click="dateSelected">
            {{ $t('bookingModal.selectBtn') }}
          </button>
        </div>
      </Teleport>
    </div>
  `,

  props: {
    startDate: Date,
    nights: Number,
    availabilityCalendar: Object,
  },
  emits: ['dateSelected'],

  setup (props, { emit }) {
    const { state } = useState()
    const { startDate, nights } = Vue.toRefs(props)
    const availabilityCalendar = props.availabilityCalendar
    const dateRange = Vue.ref([])
    const disabledDates = Vue.ref([])
    const checkOutOnlyDates = Vue.ref([])
    const maxDate = Vue.ref(null)
    const maxAvailableDate = Vue.ref(null)

    function dateSelected () {
      if (!dateRange.value[0]) {
        dateRange.value[0] = new Date()
      }
      if (!dateRange.value[1]) {
        const tomorrow = new Date(dateRange.value[0])
        tomorrow.setDate(tomorrow.getDate() + 1)
        dateRange.value[1] = tomorrow
      }

      emit('dateSelected', {
        startDate: normalizeTimezone(dateRange.value[0]),
        nights: Math.ceil((Date.parse(normalizeTimezone(dateRange.value[1]).toISOString().substring(0, 10)) - Date.parse(normalizeTimezone(dateRange.value[0]).toISOString().substring(0, 10))) / (1000 * 3600 * 24)),
      })
    }

    function onDateSelect () {
      if (checkOutOnlyDates.value.find(d => compareDates(denormalizeTimezone(d), dateRange.value[0]))) {
        dateRange.value = []
        return
      }

      const allNonAllowedDates = [...checkOutOnlyDates.value, ...disabledDates.value]
      if (allNonAllowedDates.length) {
        if (dateRange.value[1]) {
          for (const date of allNonAllowedDates) {
            if (denormalizeTimezone(date.getTime()) > dateRange.value[0].getTime() && denormalizeTimezone(date).getTime() < dateRange.value[1].getTime()) {
              delete dateRange.value[1]
              return
            }
          }
          if (disabledDates.value.find(d => compareDates(d, dateRange.value[1])) && !(checkOutOnlyDates.value.find(d => compareDates(d, dateRange.value[1])))) {
            delete dateRange.value[1]
            return
          }
        }
      }
      if (!maxAvailableDate.value) {
        maxDate.value = null
      } else if (dateRange.value[1] != null) {
        maxDate.value = maxAvailableDate.value
      } else {
        const date = new Date(dateRange.value[0])
        date.setDate(date.getDate() + MAX_NIGHTS)
        maxDate.value = date.getTime() < maxAvailableDate.value.getTime() ? date : maxAvailableDate.value
      }

      Vue.nextTick(() => {
        const selectedDays = document.querySelectorAll('td:not(.p-datepicker-other-month) .p-highlight')
        selectedDays[0]?.classList.add('first-selected')
        if (selectedDays.length >= 2) selectedDays[selectedDays.length - 1]?.classList.add('last-selected')
      }, 0)
    }

    function getCurrentDay (date) {
      const currentDate = new Date(date.year, date.month, date.day).getTime()
      return availabilityCalendar.availability.find(obj => denormalizeTimezone(obj.date).getTime() === currentDate)
    }

    function getPriceForDay (date) {
      const day = getCurrentDay(date)
      return day && 'rateFrom' in day ? new Price(day.rateFrom, day.currency) : null
    }

    function isCheckoutOnly (date) {
      const day = getCurrentDay(date)
      return !!(day && checkOutOnlyDates.value.find(d => compareDates(d, day.date)))
    }

    function getEndDate (start, nights) {
      const endDate = new Date(start)
      return new Date(endDate.setDate(endDate.getDate() + nights))
    }

    function selectFirstAvailableDate () {
      const firstAvailableDate = availabilityCalendar.minAvailableDate
      if (firstAvailableDate != null) {
        dateRange.value[0] = denormalizeTimezone(firstAvailableDate.date)
        dateRange.value[1] = denormalizeTimezone(getEndDate(firstAvailableDate.date, nights.value))
      }
    }

    dateRange.value[0] = denormalizeTimezone(startDate.value)
    dateRange.value[1] = denormalizeTimezone(getEndDate(startDate.value, nights.value))

    if (availabilityCalendar.hasAvailableDates) {
      disabledDates.value = availabilityCalendar.disabledDates.map(d => denormalizeTimezone(d))
      checkOutOnlyDates.value = [...availabilityCalendar.checkoutOnlyDates]

      maxAvailableDate.value = availabilityCalendar.maxAvailableDate.date
      maxDate.value = maxAvailableDate.value

      if (!availabilityCalendar.isDateAvailable(new Date(dateRange.value[0]))) {
        selectFirstAvailableDate()
      }
    }

    return {
      state,
      dateRange,
      disabledDates,
      maxDate,
      onDateSelect,
      dateSelected,
      getPriceForDay,
      isCheckoutOnly,
      denormalizeTimezone,
    }
  },
}
