import { compareDates } from '../utils-common'

/* global AZURE_URI, LOCALE */
class AvailabilityCalendar {
  constructor () {
    this.availability = []
    this.loading = false
  }

  get hasAvailableDates () {
    return this.availability.find(date => date.rateFrom != null)
  }

  get disabledDates () {
    return this.availability
      .filter((date, index) => !(date.rateFrom != null) && !(this.availability[index - 1]?.rateFrom != null))
      .map(obj => obj.date)
  }

  get checkoutOnlyDates () {
    return this.availability
      .filter((date, index) => (!(date.rateFrom != null) && this.availability[index - 1]?.rateFrom != null))
      .map(obj => obj.date)
  }

  get minAvailableDate () {
    return this.availability.find(day => day.rateFrom != null)
  }

  get maxAvailableDate () {
    return this.availability.length === 0 ? null : this.availability[this.availability.length - 1]
  }

  isDateAvailable (date) {
    if (date != null) {
      const selectedDateAvailability = this.availability.find(day => day.date != null && compareDates(day.date, date))
      return selectedDateAvailability != null && selectedDateAvailability.rateFrom != null
    }
    return false
  }

  isDateRangeAvailable (date, nights) {
    const startDate = new Date(new Date(date).setHours(0))
    const endDate = new Date(new Date(startDate))
    endDate.setDate(endDate.getDate() + nights)

    return this.availability
      .filter(({ date, rateFrom }) => date.getTime() >= startDate.getTime() && date.getTime() < endDate.getTime() && rateFrom != null)
      .length === nights
  }

  getFirstAvailableDateRange (nights) {
    const d = new Date(this.minAvailableDate?.date)
    const max = new Date(this.maxAvailableDate?.date)
    while (d.getTime() <= max.getTime()) {
      if (this.isDateRangeAvailable(d, nights)) return d
      d.setDate(d.getDate() + 1)
    }
    return null
  }

  async loadAvailability ({ propertyIds, unitId, accessCode }) {
    this.loading = true
    try {
      const validPropertyIds = (propertyIds || []).filter(id => id.length === 32)
      if (validPropertyIds.length === 0) {
        this.availability = []
        this.loading = false
        return
      }

      const params = {
        id: validPropertyIds.join(';'),
        accessCode: accessCode || '',
        unitId,
        locale: LOCALE,
      }
      const queryParamsString = Object.keys(params)
        .filter(key => params[key] != null)
        .map(key => `${key}=${params[key]}`)
        .join('&')
      const response = await fetch(`${AZURE_URI}GetAvailabilityCalendar?${queryParamsString}`)
      if (!response.ok) {
        throw new Error(response.statusText)
      }

      this.availability = (await response.json()).map(obj => ({
        ...obj,
        date: new Date(new Date(obj.date)),
      }))
      this.loading = false
    } catch (e) {
      this.loading = false
      throw new Error(e)
    }
  }
}

export { AvailabilityCalendar }
