/* global Vue, DATA_LAYER */
import { AppError, ErrorLevel } from '../../classes/Errors'
import { patchCartUnits, updateCartUnits, useCart } from '../../use-cart'
import LoyaltyInvite from '../../loyalty/components/LoyaltyInvite'
import { setErrorAndStopLoading } from '../components/Notifications'
import RatePolicyModal from '../components/RatePolicyModal'
import { MAX_CHILD_AGE } from '../../enums/limits'

export default {
  name: 'Step2',
  components: { LoyaltyInvite, RatePolicyModal },
  template: `
<div>
  <template v-if="hasPriceQuote">
    <LoyaltyInvite :isMember="cart.isMember.value" />
    <div v-for="(unit, i) in unitsLocal" :key="i" class="card-border unit-detail">
      <div class="card-border__wrapper">
        <div class="card-border__content">
          <h4 class="unit-detail__title">
            {{ cart.ratePlan.value?.name }}
            <span class="debug">{{ unit.id }}/{{ priceQuote.rateId }}</span>
          </h4>
          <p>{{ $t('stepTwo.selectedUnit') }} {{ i + 1 }}</p>
          <hr>

          <div class="unit-detail__items grid">
            <div class="unit-detail__item col-12 col-lg-3">
              <h5>{{ $t('stepTwo.roomType') }}</h5>
              <p class="uppercase">{{ unit.name }}</p>
            </div>

            <div class="unit-detail__item col-6 col-lg-3">
              <h5>{{ $t('stepTwo.serviceType') }}</h5>
              <p>{{ $t('serviceType.' + getPriceQuoteUnit(unit.id)?.board) }}</p>
            </div>

            <div class="unit-detail__item col-6 col-lg-2">
              <h5>{{ $t('stepTwo.nights') }}</h5>
              <p>{{ cart.nights }}</p>
            </div>

            <div class="unit-detail__item col-6 col-lg-2">
              <label class="h5" for="adults">{{ $t('stepTwo.adults') }}</label>
              <select v-if="unit.occupancyMaxAdult > 1" v-model="unit.adults" name="adults">
                <option v-for="n in getMaxPossibleAdults(unit)" :value="n" :text="n" />
              </select>
              <p v-else>1</p>
            </div>

            <div class="unit-detail__item col-6 col-lg-2" v-if="property.maxChildAge !== 0">
              <label class="h5" for="children">{{ $t('stepTwo.children') }}</label>
              <select :value="unit.children.length" @change="childrenChange($event, i)" name="children">
                <option value="0">0</option>
                <option v-for="n in getMaxPossibleChildren(unit)" :value="n" :text="n" />
              </select>
            </div>
          </div>

          <div v-if="unit.children.length > 0" class="unit-detail__items grid">
            <div class="unit-detail__item col-6 col-lg-2" v-for="(child, i) in unit.children" :key="i">
              <label class="h5" for="'age-' + i">{{ $t('bookingModal.age') }}</label>
              <select v-model="child.age" :name="'age-' + i">
                <option v-for="n in maxChildAge" :value="n">{{ n }}</option>
              </select>
            </div>
          </div>

          <div v-if="unit.subProperty === 'pitches'">
            <hr style="margin: 18px 0 24px">
            <div class="unit-detail__items grid">
              <div class="unit-detail__item col-12 col-lg-8">
                <h5>{{ $t('stepTwo.vehicle') }}</h5>
              </div>

              <div class="unit-detail__item col-6 col-lg-2">
                <label class="h5" for="vehicleType">{{ $t('stepTwo.vehicleType') }}</label>
                <select v-model="unit.vehicleType" name="vehicleType" id="vehicleType">
                  <option></option>
                  <option v-for="vehicleType of ['CAMPING_CAR', 'CARAVAN', 'TENT']" :value="vehicleType">{{ $t('stepTwo.vehicleTypes.' + vehicleType) }}</option>
                </select>
              </div>

              <div class="unit-detail__item col-6 col-lg-2">
                <label class="h5" for="vehicleLength">{{ $t('stepTwo.vehicleLength') }}</label>
                <select v-model="unit.vehicleLength" name="vehicleLength" id="vehicleLength">
                  <option></option>
                  <option :value="[0, 5]">0 - 5 m</option>
                  <option :value="[5, 6]">5 - 6 m</option>
                  <option :value="[6, 7]">6 - 7 m</option>
                  <option :value="[7, 8]">7 - 8 m</option>
                  <option :value="[8, 30]">> 8 m</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="unit.services?.length">
            <hr style="margin: 18px 0 24px">
            <div class="grid">
              <div class="col-12 col-lg-6">
                <h5 class="no-margin">{{ $t('stepTwo.services') }}</h5>
              </div>
              <div class="col-2 hide-lg-down">
                <h5>{{ $t('stepTwo.rate') }}</h5>
              </div>
              <div class="col-2 hide-lg-down">
                <h5>{{ $t('stepTwo.charge') }}</h5>
              </div>
              <div class="col-2 hide-lg-down">
                <h5>{{ $t('stepTwo.qty') }}</h5>
              </div>
            </div>

            <div v-for="(service, index) in unit.services" :key="index" class="unit-detail__service grid">
              <div class="col-12 col-lg-6">
                <p>{{ service.name }}</p>
              </div>
              <div class="col-4 col-lg-2">
                <h5 class="hide-lg-up">{{ $t('stepTwo.rate') }}</h5>
                <p>{{ service.price.display }}</p>
              </div>
              <div class="col-4 col-lg-2">
                <h5 class="hide-lg-up">{{ $t('stepTwo.charge') }}</h5>
                <p>{{ $t('chargeType.' + service.priceType) }}</p>
              </div>
              <div class="col-4 col-lg-2">
                <h5 class="hide-lg-up">{{ $t('stepTwo.qty') }}</h5>
                <select v-model="service.quantity" :name="service.id + '_' + i + '_' + index">
                  <option value="0">0</option>
                  <option v-for="n in maxServiceQuantity(unit, service)" :value="n" :text="n" />
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="unit-detail__total">
          <span>{{ $t('stepTwo.unitTotal') }}</span>
          <a @click="showRatePolicy = true">{{ $t('ratePlan.ratePolicy') }}</a>
          <span>{{ priceQuote?.units[i]?.total?.display }}</span>
        </div>
      </div>
    </div>

    <RatePolicyModal :isOpened="showRatePolicy" :rateId="priceQuote?.rateId" :extraInformation="extras ? [extras] : []" @success="showRatePolicy = false" />
  </template>

  <teleport to="#continue-button">
    <div class="reservation-summary__disclaimer" v-if="unallocatedGuests" v-html="$t('reservationSummary.guestSufficiency', { n: unallocatedGuests })"></div>
    <button v-if="unitsChanged && !unallocatedGuests" class="button" @click="updatePrice" :disabled="unallocatedGuests">{{ $t('stepTwo.updatePrice') }}</button>
    <button v-else-if="hasTooManyGuests" @click="$router.push('/step1')">{{ $t('back') }}</button>
    <button v-else @click="$router.push('/step3')" :disabled="unallocatedGuests">{{ $t('stepTwo.continue') }}</button>
  </teleport>
</div>`,
  props: ['priceQuote', 'extras', 'property'],
  emits: ['updatePrice'],
  data() {
    return { showRatePolicy: false }
  },
  setup (props, { emit }) {
    const { cart } = useCart()
    const { t } = VueI18n.useI18n()
    const unitsLocal = Vue.reactive(cart.selectedUnits.value)
    const unitsChanged = Vue.ref(false)
    const maxChildAge = unitsLocal[0]?.maxChildAge ? unitsLocal[0].maxChildAge : ( props.property.maxChildAge ? props.property.maxChildAge : MAX_CHILD_AGE)

    const hasTooManyGuests = Vue.computed(() => {
      return cart.guestsOverMaxOccupancy.value.adults > 0 || cart.guestsOverMaxOccupancy.value.children > 0
    })

    const unallocatedGuests = Vue.computed(() => {
      let { adults, children } = cart.guestsOverMaxOccupancy.value

      if (Math.max(0, adults) + Math.max(0, children) > 0) {
        return Math.max(0, adults) + Math.max(0, children)
      }

      let unallocatedGuests = 0
      const adultsPlaced = unitsLocal.reduce((sum, unit) => sum + unit.adults, 0)
      const childrenPlaced = unitsLocal.reduce((sum, unit) => sum + unit.children.length, 0)

      if (adultsPlaced < cart.adults.value) {
        unallocatedGuests += cart.adults.value - adultsPlaced
      }

      if (childrenPlaced < cart.children.value.length) {
        unallocatedGuests += cart.children.value.length - childrenPlaced
      }

      return unallocatedGuests
    })

    const updatePrice = () => {
      updateCartUnits(unitsLocal)

      emit('updatePrice')
    }

    const childrenChange = (e, index) => {
      let childCounter = 0
      unitsLocal.forEach((u, i) => {
        let childrenInThisUnit = u.children.length
        if (i === index) {
          childrenInThisUnit = parseInt(e.target.value)
        }
        u.children = []
        for (let i = 0; i < childrenInThisUnit; i += 1) {
          u.children.push(cart.children.value[childCounter] ?? { age: 1 })
          childCounter += 1
        }
      })
    }

    const getMaxPossibleAdults = unit => Math.min(...[
      unit.occupancyMaxAdult,
      unit.occupancyMax - unit.children.length
    ])

    const getMaxPossibleChildren = unit => Math.min(...[
      unit.occupancyMaxChildren,
      unit.occupancyMax - unit.adults
    ])

    const getPriceQuoteUnit = unitId => props.priceQuote?.units.find(u => u.unitId === unitId)

    if (unitsLocal.length === 0 || !cart.rateId) {
      // setErrorAndStopLoading(new AppError('unitOrRateMissing', ErrorLevel.FATAL))
    }

    const priceRelatedUnitData = Vue.computed(() => unitsLocal.map(({ adults, children, services }) => ({
      adults,
      children,
      services,
    })));

    const additionalUnitData = Vue.computed(() => unitsLocal.map(({ vehicleType, vehicleLength }) => ({
      vehicleType,
      vehicleLength,
    })));

    Vue.onMounted(() => {
      document.querySelector('h1').scrollIntoView()

      try {
        unitsLocal.forEach((unit) => {
          const localServices = unit.services ?? []
          unit.services = getPriceQuoteUnit(unit.id).services.map((service) => ({
            ...service,
            quantity: localServices.find(ls => ls.id == service.id)?.quantity ?? 0
          }))
        })

      } catch (err) {
        setErrorAndStopLoading(err)
      }

      Vue.watch(priceRelatedUnitData, () => unitsChanged.value = true, { deep: true })
      Vue.watch(additionalUnitData, () => patchCartUnits(additionalUnitData.value), { deep: true })

      if (cart.rateId && unitsLocal.length > 0) {
        DATA_LAYER.bookingReservationDetails({ cart, priceQuote: props.priceQuote, property: props.property })
      }
    })

    const hasPriceQuote = Vue.computed(() => props.priceQuote?.rateId)

    const maxServiceQuantity = ({ services }, { serviceGroup, maxQuantity, quantity }) => {
      if (!serviceGroup) return maxQuantity

      const availableQuantity = maxQuantity - services
        .filter(service => service.serviceGroup && service.serviceGroup === serviceGroup)
        .reduce((acc, service) => acc + service.quantity, 0)

      const qty = parseInt(quantity)
      const availableQty = parseInt(availableQuantity)

      return qty > availableQty ? qty : availableQty
    }

    return {
      cart,
      hasPriceQuote,
      unitsLocal,
      unitsChanged,
      maxChildAge,
      unallocatedGuests,
      hasTooManyGuests,
      getMaxPossibleAdults,
      getMaxPossibleChildren,
      getPriceQuoteUnit,
      childrenChange,
      updatePrice,
      maxServiceQuantity,
    }
  }
}
