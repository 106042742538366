const ErrorLevel = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
}
Object.freeze(ErrorLevel)

class AppError extends Error {
  constructor (code, level) {
    super(code)
    this.name = this.constructor.name
    this.level = level
    this.code = code
  }
}

// when we get structured response from Beterna, success = false
class BeternaError extends AppError {
  constructor (code, level, raw, extra) {
    super(code, level)
    this.raw = raw
    this.extra = extra
  }
}

class PhobsError extends AppError {
  constructor (code, PhobsError, Error) {
    super(code, PhobsError.type)
    // Error is generic message from booking-proxy
    this.details = Error
    // PhobsError is json format from booking-proxy
    this.code = `phobsError${PhobsError.id}`
    this.rawCode = parseInt(PhobsError.code)
    this.xmlRequest = PhobsError.PhobsRequest
    this.xmlResponse = PhobsError.PhobsResponse
  }
}

class RestError extends AppError {
  constructor (code, httpStatus, raw, extra) {
    super(code, ErrorLevel.ERROR)
    this.httpStatus = httpStatus
    this.raw = raw
    this.extra = extra
  }
}

export {
  AppError,
  PhobsError,
  RestError,
  BeternaError,
  ErrorLevel,
}
