import { clone } from './utils'
import { parseChildrenFromUrl } from './utils-common'
import { MAX_GUESTS, MAX_ADULTS, MAX_CHILDREN, MAX_NIGHTS, MAX_CHILD_AGE } from './enums/limits'

const _cart = Vue.reactive({
  isDirty: 0,
  propertyId: '',
  accessCode: '',
  authentication: false,
  loyaltyId: -1,
  becomeMember: true,
  adults: 2,
  bookingId: '',
  children: [],
  startDate: new Date(),
  nights: 1,
  ratePair: {},
  rateId: '',
  get rateAltId() {
      if (this.ratePair?.member?.id === this.rateId) return this.ratePair.regular?.id
      return null
  },
  get canUseMemberRate() {
    return  this.becomeMember || this.loyaltyId > 0
  },
  get isRegularRateSelected() {
    return this.ratePair.regular?.id === this.rateId
  },
  get isMemberRateSelected() {
    return this.ratePair.member?.id === this.rateId
  },
  selectedUnits: [],
  client: {},
  payment: {
    method: {}
  },
  guestLimits: null,
})

Vue.watch(_cart, (currentValue) => {
  window.sessionStorage.setItem('cart', JSON.stringify(currentValue))
}, { deep: true })

const resetBookingNumber = () => {
  _cart.bookingId = (Math.floor((Math.random() * 9999999999999) + 100000000)).toString()
}

const loadCartFromUrl = urlParams =>  {
  _cart.propertyId = urlParams.get('propertyId')
  if (urlParams.has('nights')) {
    _cart.nights = parseInt(urlParams.get('nights'))
  }
  if (urlParams.has('bookingId')) {
    _cart.bookingId = urlParams.get('bookingId')
  } else {
    resetBookingNumber()
  }

  if (urlParams.has('date')) {
    const urlDate = new Date(Date.parse(urlParams.get('date')))
    _cart.startDate = urlDate
  } else {
    _cart.startDate = new Date()
  }
  _cart.accessCode = urlParams.get('accessCode') || ''
  _cart.adults = parseInt(urlParams.get('adults')) || _cart.adults
  _cart.children = parseChildrenFromUrl(urlParams.get('children'))

  _cart.rateId = ''
  _cart.selectedUnits = []

  ensureCartParametersInAllowedRange()
}

const hasClient = () => typeof _cart.client === 'Object' && _cart.client.email?.length && _cart.client.firstName?.length
const hasPropertyId = () => _cart.propertyId?.length === 32 && _cart.propertyId !== ''
const hasRateId = () => _cart.rateId?.length > 1 && _cart.rateId !== ''

const guestsOverMaxOccupancy = () => {
  const { selectedUnits, adults, children } = _cart
  const availableAdultCapacity = selectedUnits.reduce((sum, unit) => sum + unit.occupancyMaxAdult, 0)
  const requiredAdultCapacity = adults - availableAdultCapacity
  const adultsPlaced = adults - (requiredAdultCapacity > 0 ? requiredAdultCapacity : 0)
  const availablOverallOccupancy = selectedUnits.reduce((sum, unit) => sum + unit.occupancyMax, 0) - adultsPlaced
  const requiredChildrenCapacity =  children.length - availablOverallOccupancy

  return {
    adults: requiredAdultCapacity > 0 ? requiredAdultCapacity : 0,
    children: requiredChildrenCapacity > 0 ? requiredChildrenCapacity : 0,
  }
}
const hasRequiredCapacity = () => {
  const { adults, children } = guestsOverMaxOccupancy()
  return adults + children === 0
}

const loadCartFromJson = json => {
  try {
    const rawCart = JSON.parse(json)
    if (hasPropertyId() && _cart.propertyId !== rawCart.propertyId ) {
      console.warn(`propertyId is not the same ${_cart.propertyId} / ${rawCart.propertyId}`)
    } else {
      for (const [key, value] of Object.entries(rawCart)) {
        if (key === "startDate") {
          try {
            _cart.startDate = new Date(Date.parse(value))
          } catch(e) {
            console.warn(`bad date in session ${e}`)
          }
        } else if (key === "loyaltyId" || key == 'authentication') {

        } else if (_cart.hasOwnProperty(key)) {
          _cart[key] = value
        }
      }
    }
  } catch (e) {
    console.warn(e)
  }

  ensureCartParametersInAllowedRange()
}

const updateCartUnits = unitsIn => {
  const dataValid = unitsIn.every(unit => unit.adults + unit.children.length <= unit.occupancyMax)
  if (!dataValid) {
    console.warn(`Max unit occupancy exceeded`)
    return
  }

  _cart.selectedUnits = _cart.selectedUnits.map((unit, index) => {
    const { adults, children, services } = unitsIn[index]
    return {
      ...unit,
      adults,
      children,
      services
    }
  });

  ensureCartParametersInAllowedRange()
}

const patchCartUnits = unitsIn => {
  _cart.selectedUnits = _cart.selectedUnits.map((unit, index) => {
    return {
      ...unit,
      ...unitsIn[index],
    }
  });
}

function ensureCartParametersInAllowedRange() {
  if (_cart.nights > MAX_NIGHTS) {
    _cart.nights = MAX_NIGHTS
  }
  if (_cart.adults <= 0) {
    // console.warn('Number of adults was set to a negative number, changing to 1')
    _cart.adults = 1
  }

  if (_cart.guestLimits) {
    if (_cart.adults >= _cart.guestLimits.maxAdults) {
      _cart.adults = _cart.guestLimits.maxAdults
    }

    if (_cart.guestLimits.maxChildAge === 0) {
      _cart.children = []
    }

    const guestsAvailable = _cart.guestLimits.maxGuests - _cart.adults
    const maxChildren = guestsAvailable < _cart.guestLimits.maxChildren ? guestsAvailable : _cart.guestLimits.maxChildren

    if (_cart.children.length > maxChildren) {
      _cart.children = _cart.children.splice(0, maxChildren)
    }
  }

  if (_cart.children.some(({age}) => age > MAX_CHILD_AGE)) {
    _cart.children = _cart.children.map(child => ({
      ...child,
      age: child.age > MAX_CHILD_AGE ? MAX_CHILD_AGE : child.age
    }))
  }
}

function map2SerializedUnits(selectedUnits) {
  return selectedUnits.map(({ id, adults, children, services, vehicleType, vehicleLength, occupancyMin }) => (
    {
      unitId: id,
      adults: adults || occupancyMin,
      children,
      services: services.map(({ id, quantity }) => ({ id, quantity })),
      vehicle: {
        type: vehicleType,
        sizeMin: vehicleLength ? vehicleLength[0] : null,
        sizeMax: vehicleLength ? vehicleLength[1] : null,
      }
    }
  ))
}

function useCart () {
  return {
    cart: {
      getSerializedForAvailability: () => {
        const result = {
          accessCode: _cart.accessCode,
          date: _cart.startDate.toISOString().split('T')[0],
          nights: _cart.nights,
          propertyId: _cart.propertyId,
          adults: _cart.adults,
          children: _cart.children
        }
        return result
      },
      getSerializedForQuote: () => {
        const result = {
          accessCode: _cart.accessCode,
          bookingId: _cart.bookingId,
          date: _cart.startDate.toISOString().split('T')[0],
          nights: _cart.nights,
          propertyId: _cart.propertyId,
          loyaltyId: _cart.loyaltyId,
          becomeMember: _cart.becomeMember,
          rateId: _cart.rateId,
          rateAltId: _cart.rateAltId,
          units: map2SerializedUnits(_cart.selectedUnits),
          client: _cart.client,
          payment: {
            type: _cart.payment?.method?.type,
            cardType: _cart.payment?.cardType,
            validMonth: _cart.payment?.validMonth,
            validYear: _cart.payment?.validYear,
            cvv: _cart.payment?.cvv,
            firstName: _cart.payment?.firstName,
            lastName: _cart.payment?.lastName,
          }
        }
        return result
      },
      propertyId: Vue.computed(() => _cart.propertyId),
      rateId: Vue.computed(() => _cart.rateId),
      loyaltyId: Vue.computed(() => _cart.loyaltyId),
      isMember: Vue.computed(() => _cart.loyaltyId > 0),
      isAuthenticated: Vue.computed(() => _cart.authentication),
      becomeMember: Vue.computed(() => _cart.becomeMember),
      canUseMemberRate: Vue.computed(() => _cart.canUseMemberRate),
      ratePlan: Vue.computed(() => {
        if (_cart.isMemberRateSelected)  return _cart.ratePair.member
        if (_cart.isRegularRateSelected) return _cart.ratePair.regular
        return null
      }),
      ratePlanIsMemberOnly: Vue.computed(() => _cart.ratePair?.member?.id === _cart.rateId),
      includesPitches: Vue.computed(() => _cart.selectedUnits.some(unit => unit.subProperty === 'pitches')),
      accessCode: Vue.computed(() => _cart.accessCode),
      nights: Vue.computed(() => _cart.nights),
      adults: Vue.computed(() => _cart.adults),
      children: Vue.computed(() => _cart.children),
      noGuests: Vue.computed(() => _cart.children.length + _cart.adults),
      noChildren: Vue.computed(() => _cart.children.length),
      dirtySignal: Vue.computed(() => _cart.isDirty),
      selectedUnits: Vue.computed(() => _cart.selectedUnits.map(unit => ({
          ...unit,
          children: unit.children.map(child => ({ ...child })),
          services: unit.services.map((service) => ({ ...service })),
          rates: null,
        }))
      ),
      unitLimitReached: Vue.computed(() => _cart.selectedUnits.length >= 3),
      guestSummary: Vue.computed(() => _cart.selectedUnits.reduce((sum, unit) => ({
          adults: sum.adults + unit.adults,
          children: sum.children + unit.children.length
        }), { adults: 0, children: 0 })
      ),
      minOccupancyRemaining: Vue.computed(() => _cart.adults - _cart.selectedUnits.reduce((sum, unit) => sum + unit.occupancyMin, 0)),
      childAges: Vue.computed(() => hasRateId() ?
        _cart.selectedUnits.reduce((sum, unit) => sum.concat(unit.children.map(c => c.age)), []) :
        _cart.children.map(c => c.age)
      ),
      guestsOverMaxOccupancy: Vue.computed(guestsOverMaxOccupancy),
      payment: Vue.computed(() => _cart.payment),
      client: Vue.computed(() => _cart.client),
      startDate: Vue.computed(() => _cart.startDate),
      endDate: Vue.computed(() => {
        if (!_cart.startDate || !_cart.nights) {
          return
        }
        const d = new Date(_cart.startDate)
        d.setDate(d.getDate() + _cart.nights)
        return d
      }),
      hasPropertyId: Vue.computed(hasPropertyId),
      hasRateId: Vue.computed(hasRateId),
      canGo2Step2: Vue.computed(hasPropertyId && hasRateId && hasRequiredCapacity),
      canGo2Step4: Vue.computed(hasPropertyId && hasRateId && hasClient && hasRequiredCapacity),
    }
  }
}

function setIdentity(loyaltyId) {
  _cart.authentication = true
  if (loyaltyId && !isNaN(loyaltyId)) {
    _cart.becomeMember = false
    _cart.loyaltyId = loyaltyId

    toggleMemberRate()
  }
}

function setBecomeMember(value) {
  if (typeof value === "string") {
    if (value === "true") value = true
    if (value === "false") value = false
  }
  if (typeof value !== "boolean") {
    throw TypeError("setBecomeMember needs boolean ...")
  }
  _cart.becomeMember = value

  toggleMemberRate()
}

function toggleMemberRate() {
  // Warning: this will potentially clear the services but does not redirect to step 2 when necessary
  let rateChanged = false

  if (!_cart.canUseMemberRate && _cart.isMemberRateSelected) {
    _cart.rateId = _cart.ratePair.regular?.id
    rateChanged = true
  }

  if (_cart.canUseMemberRate  && _cart.isRegularRateSelected && !!_cart.ratePair.member) {
    _cart.rateId = _cart.ratePair.member.id
    rateChanged = true
  }

  if (rateChanged) {
    _cart.isDirty += 1
    _cart.selectedUnits.forEach(unit => unit.services = [])
  }
}

function setClient(client) {
  if (typeof client !== 'object' || !client.email?.length) {
    throw new Error("missing client")
  }
  _cart.client = JSON.parse(JSON.stringify(client))
}

const maskCardNumber = number => {
  return (number.slice(0, number.length - 4).split('').map((char) => 'X')).reduce((str, char) => {
    return str + char
  }) + number.slice(-4)
}

function setPayment(payment) {
  if (!payment?.method) {
    console.log(payment)
    throw new Error("missing payment")
  }
  Object.assign(_cart.payment, payment)
}

function assignGuestsToUnits() {
  adults = _cart.adults
  children = [..._cart.children]

  _cart.selectedUnits.forEach(unit => {
    unit.adults = 0
    unit.children = []

    while (unit.adults < unit.occupancyMin && adults > 0) {
      unit.adults += 1
      adults -= 1
    }
  })

  _cart.selectedUnits.forEach(unit => {
    while (unit.adults < unit.occupancyMaxAdult && adults > 0) {
      unit.adults += 1
      adults -= 1
    }

    const occupancyMaxChildren = unit.occupancyMax - unit.adults
    while (unit.children.length < occupancyMaxChildren && children.length > 0) {
      unit.children.push(children.shift())
    }
  })
}

function selectRatePlanPair (ratePair, unit) {
  if (!ratePair?.regular) {
    throw new Error("missing rate or regular rate")
  }
  if (!unit) {
    throw new Error("updateRatePlan without selectedUnit")
  }

  if (!hasRateId()) {
    _cart.ratePair = clone(ratePair)
    _cart.rateId = ratePair.member && _cart.canUseMemberRate ? ratePair.member.id : ratePair.regular.id
  }

  _cart.selectedUnits.push({
    ...unit,
    services: [],
    adults: 0,
    children: [],
  })

  assignGuestsToUnits()

  _cart.isDirty += 1
}

function removeUnitByIndex (index) {
  _cart.selectedUnits.splice(index, 1)
  if (_cart.selectedUnits.length === 0) {
    clearRateId()
  }
  _cart.isDirty += 1
}

const updateBookingParameters = p => {
  if (Object.prototype.toString.call(p.startDate) !== '[object Date]' || isNaN(p.startDate)) {
    throw new Error("InvalidArgumentException startDate")
  }
  if (!Array.isArray(p.children)) {
    throw new Error("InvalidArgumentException children")
  }
  if (isNaN(p.adults) || isNaN(p.nights)) {
    throw new Error("InvalidArgumentException adults or nights")
  }

  _cart.startDate = p.startDate
  _cart.nights = p.nights
  _cart.adults = p.adults
  _cart.children = p.children
  _cart.accessCode = p.accessCode
  ensureCartParametersInAllowedRange()
  _cart.selectedUnits = []
  _cart.ratePair = {}
  _cart.rateId = ''
  resetBookingNumber()
  _cart.payment = { method: {} }
  _cart.isDirty += 1
}

const clearRateId = () => {
  _cart.rateId = ''
  _cart.selectedUnits = []
  _cart.ratePair = {}
}

const updateBookingGuests = ({ adults, children }) => {
  _cart.adults = adults
  _cart.children = children
}

const modifyGuestNumbersAccordingToMaxChildAge = (maxChildAge) => {
  let adults = _cart.adults
  let children = _cart.children.map(c => ({ age: c.age }))
  if (maxChildAge) {
    children = children.filter(c => c.age <= maxChildAge)
    adults += _cart.children.length - children.length
  }
  return { adults, children }
}

const updateGuestLimits = ({ maxGuests, maxAdults, maxChildren, maxChildAge}) => {
  _cart.guestLimits = { maxGuests, maxAdults, maxChildren, maxChildAge }
  ensureCartParametersInAllowedRange()
}

const updateBookingNights = nights => {
  _cart.nights = nights
  ensureCartParametersInAllowedRange()
  _cart.isDirty += 1
}


const clearCart = () => window.sessionStorage.clear()

export {
  loadCartFromUrl,
  loadCartFromJson,
  useCart,
  updateGuestLimits,
  updateCartUnits,
  patchCartUnits,
  map2SerializedUnits,
  setPayment,
  setClient,
  selectRatePlanPair,
  removeUnitByIndex,
  modifyGuestNumbersAccordingToMaxChildAge,
  updateBookingParameters,
  updateBookingNights,
  updateBookingGuests,
  maskCardNumber,
  clearRateId,
  clearCart,
  resetBookingNumber,
  setIdentity,
  setBecomeMember
}
